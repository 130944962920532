type Props = {
  /**
   * 横幅。この値に応じて縦幅は自動で計算
   */
  width?: number
  className?: string
}

/**
 * サイトロゴ
 */
const AppLogo = ({ width = 89, className = '' }: Props) => {
  return (
    <svg
      className={`inline-block ${className}`}
      width={width}
      height={width * (26 / 89)}
      viewBox="0 0 89 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Logiura</title>
      <path
        d="M3.39858 16.4806H11.7395V18.7684H0.786682V0.366943H3.39858V16.4806Z"
        fill="#457AEB"
      />
      <path
        d="M26.8046 12.5865C26.8181 13.4848 26.6462 14.3764 26.2994 15.2074C25.9727 15.984 25.4879 16.6864 24.8747 17.2714C24.2573 17.8581 23.5274 18.3179 22.7276 18.6241C21.0096 19.2595 19.1158 19.2595 17.3978 18.6241C16.5982 18.3204 15.8695 17.8602 15.2557 17.2714C14.652 16.6818 14.1729 15.9803 13.8462 15.2074C13.4994 14.3764 13.3275 13.4848 13.341 12.5865C13.3261 11.6913 13.498 10.8026 13.8462 9.97549C14.1732 9.20862 14.6524 8.51368 15.2557 7.93142C15.8732 7.35209 16.6012 6.89907 17.3978 6.59856C19.1143 5.95543 21.0111 5.95543 22.7276 6.59856C23.5246 6.90117 24.2539 7.35391 24.8747 7.93142C25.4875 8.50899 25.9725 9.20488 26.2994 9.97549C26.6476 10.8026 26.8195 11.6913 26.8046 12.5865ZM24.223 12.5865C24.2248 12.0145 24.1292 11.4463 23.9401 10.9055C23.7674 10.3881 23.4946 9.90846 23.1368 9.49306C22.7763 9.07281 22.3284 8.73362 21.8233 8.49839C21.2588 8.26103 20.6513 8.13868 20.0374 8.13868C19.4236 8.13868 18.8161 8.26103 18.2515 8.49839C17.7465 8.73362 17.2985 9.07281 16.938 9.49306C16.5802 9.90846 16.3075 10.3881 16.1347 10.9055C15.7642 11.9982 15.7642 13.1798 16.1347 14.2724C16.3108 14.7914 16.5832 15.2737 16.938 15.6948C17.2976 16.116 17.7458 16.4554 18.2515 16.6895C18.8155 16.9291 19.4232 17.0527 20.0374 17.0527C20.6517 17.0527 21.2594 16.9291 21.8233 16.6895C22.3291 16.4554 22.7772 16.116 23.1368 15.6948C23.4917 15.2737 23.7641 14.7914 23.9401 14.2724C24.1208 13.7318 24.2096 13.1655 24.2028 12.5964L24.223 12.5865Z"
        fill="#457AEB"
      />
      <path
        d="M31.2099 20.872C31.7714 21.4916 32.4602 21.9866 33.2308 22.3242C34.023 22.6833 34.8846 22.8699 35.7568 22.8713C36.4691 22.8907 37.1783 22.7706 37.8432 22.5182C38.3685 22.3112 38.8348 21.9818 39.2022 21.5583C39.5523 21.1364 39.804 20.6441 39.9398 20.116C40.0938 19.5315 40.1685 18.9295 40.1621 18.3256V16.7391H40.1116C39.6528 17.4327 39.0026 17.983 38.2373 18.3256C37.4464 18.6887 36.5839 18.8755 35.7113 18.8727C34.8187 18.8827 33.9334 18.7135 33.1095 18.3754C32.3574 18.0684 31.6792 17.6092 31.119 17.0276C30.5685 16.4523 30.1378 15.7765 29.851 15.0383C29.5472 14.2436 29.3948 13.4006 29.4013 12.5516C29.3959 11.7026 29.5483 10.8599 29.851 10.0649C30.1334 9.30978 30.5643 8.61686 31.119 8.02581C31.6749 7.43637 32.3494 6.96724 33.0994 6.64821C33.9146 6.30487 34.7944 6.13538 35.681 6.15088C36.5726 6.14828 37.4533 6.34377 38.2575 6.7228C39.0679 7.0968 39.7443 7.70366 40.1975 8.46347H40.248V6.47413H42.6477V18.1615C42.6524 19.0346 42.5385 19.9043 42.3092 20.7477C42.0888 21.5742 41.6759 22.3388 41.1033 22.981C40.5307 23.6232 39.8141 24.1254 39.0103 24.4478C37.9707 24.8585 36.8562 25.0532 35.7366 25.0198C34.616 25.0148 33.5048 24.8179 32.4527 24.4379C31.4049 24.0774 30.4399 23.5162 29.6135 22.7867L31.2099 20.872ZM31.9526 12.482C31.9499 13.039 32.0439 13.5923 32.2305 14.1182C32.4096 14.6273 32.6899 15.0962 33.0551 15.4975C33.4203 15.8988 33.863 16.2245 34.3574 16.4557C34.9046 16.7055 35.5023 16.8296 36.1054 16.8187C36.7054 16.8249 37.3003 16.7098 37.8533 16.4805C38.3646 16.2699 38.8244 15.9546 39.2022 15.5555C39.5793 15.1585 39.8729 14.692 40.0661 14.1828C40.2708 13.6386 40.3735 13.0623 40.3693 12.482C40.3732 11.913 40.2704 11.3482 40.0661 10.8159C39.8653 10.3067 39.5688 9.83933 39.1921 9.43826C38.8092 9.03772 38.3489 8.71646 37.8382 8.49331C37.0417 8.13204 36.1496 8.02793 35.2894 8.19586C34.4293 8.36379 33.645 8.79516 33.0489 9.4283C32.6823 9.82688 32.4036 10.296 32.2305 10.8059C32.0423 11.3451 31.9483 11.9118 31.9526 12.482Z"
        fill="#457AEB"
      />
      <path
        d="M49.8166 2.01324C49.8195 2.22389 49.7758 2.43264 49.6886 2.62502C49.6013 2.81739 49.4726 2.98876 49.3114 3.12727C48.9957 3.41875 48.5771 3.57755 48.1444 3.5699C47.9314 3.57393 47.7198 3.53595 47.522 3.45823C47.3241 3.3805 47.1441 3.26458 46.9925 3.11731C46.841 2.97518 46.721 2.80371 46.6402 2.61369C46.5593 2.42367 46.5193 2.21924 46.5227 2.01324C46.5172 1.80255 46.5562 1.59301 46.637 1.39785C46.7179 1.2027 46.8389 1.02615 46.9925 0.879301C47.1434 0.731107 47.3233 0.614596 47.5213 0.536806C47.7193 0.459016 47.9313 0.421579 48.1444 0.426739C48.3596 0.422393 48.5736 0.460149 48.7739 0.537838C48.9743 0.615527 49.1569 0.731588 49.3114 0.879301C49.4731 1.02169 49.6019 1.19661 49.6891 1.39224C49.7763 1.58787 49.8197 1.79965 49.8166 2.01324ZM49.4175 18.7685H46.9369V6.45445H49.4175V18.7685Z"
        fill="#457AEB"
      />
      <path
        d="M62.5628 18.7684C62.5223 18.4551 62.4971 18.102 62.4819 17.714C62.4668 17.3261 62.4819 16.9979 62.4819 16.7194H62.4264C62.072 17.4196 61.517 18.0023 60.8299 18.3954C60.0831 18.853 59.219 19.0912 58.3393 19.0817C57.6188 19.0991 56.9027 18.9669 56.2376 18.6938C55.6737 18.4531 55.1766 18.0831 54.7877 17.6146C54.4008 17.1434 54.1138 16.6005 53.944 16.0181C53.7539 15.3854 53.6603 14.7284 53.6662 14.0686V6.45435H56.1467V13.2579C56.1458 13.741 56.1847 14.2233 56.2629 14.7002C56.332 15.1264 56.4811 15.5362 56.7024 15.9087C56.9137 16.2553 57.2132 16.5415 57.5714 16.7392C58.0103 16.9644 58.5015 17.0724 58.996 17.0526C59.4603 17.0666 59.9215 16.9741 60.3432 16.7825C60.765 16.5909 61.1357 16.3053 61.4261 15.9485C62.0797 15.1044 62.4073 14.0591 62.3506 12.9993V6.45435H64.8261V16.1176C64.8261 16.4458 64.8261 16.8686 64.8564 17.3908C64.8867 17.913 64.8968 18.3854 64.9322 18.7684H62.5628Z"
        fill="#3E4145"
      />
      <path
        d="M69.0799 7.8319C69.0799 7.33456 69.0344 6.83722 68.9991 6.45427H71.3483C71.3836 6.76262 71.4089 7.12567 71.424 7.53348C71.4392 7.9413 71.4544 8.26954 71.4544 8.52815H71.5301C71.8708 7.8136 72.4059 7.20594 73.0761 6.77257C73.7525 6.33143 74.5482 6.10093 75.3596 6.11111C75.6878 6.10463 76.0155 6.13975 76.3346 6.21556L76.2285 8.47345C75.8562 8.38593 75.4746 8.34251 75.0918 8.34414C74.5548 8.33225 74.0222 8.44291 73.5358 8.66742C73.1108 8.87392 72.7379 9.17128 72.4446 9.53776C72.1454 9.91203 71.9255 10.3416 71.7979 10.801C71.655 11.2858 71.5836 11.7882 71.5857 12.293V18.7584H69.1052V9.10508C69.1052 8.75197 69.0951 8.34913 69.0799 7.8319Z"
        fill="#3E4145"
      />
      <path
        d="M86.4387 10.791C86.4696 10.4099 86.4095 10.0269 86.2629 9.67279C86.1164 9.31868 85.8876 9.0033 85.595 8.75194C84.9473 8.28307 84.1547 8.04935 83.3519 8.09048C82.6671 8.08666 81.9891 8.22388 81.3614 8.49332C80.7776 8.73582 80.2448 9.08325 79.7902 9.51783L78.4716 7.96117C79.1238 7.3672 79.8898 6.90732 80.7248 6.60843C81.6412 6.2735 82.6118 6.105 83.5893 6.11109C84.3844 6.09278 85.1761 6.22098 85.9234 6.48907C86.536 6.70778 87.0916 7.05771 87.5501 7.51358C87.9734 7.95213 88.2972 8.47428 88.4999 9.04539C88.7109 9.65539 88.8167 10.2958 88.8131 10.9402V16.3164C88.8131 16.7342 88.8131 17.1718 88.8535 17.6294C88.8691 18.0108 88.9181 18.3902 89 18.7633H86.7569C86.6167 18.1832 86.5455 17.5891 86.5447 16.9928H86.4639C86.0253 17.6373 85.4318 18.1652 84.7361 18.5296C83.9724 18.9078 83.1254 19.0941 82.2707 19.0717C81.7494 19.0681 81.2304 19.003 80.7248 18.8777C80.2033 18.7534 79.7088 18.5377 79.2648 18.2411C78.814 17.9384 78.4392 17.5388 78.1685 17.0724C77.8615 16.5254 77.7113 15.9065 77.734 15.282C77.6888 14.472 77.962 13.6759 78.4969 13.0589C79.0544 12.4836 79.749 12.0545 80.5177 11.8106C81.4214 11.5073 82.3598 11.3151 83.3115 11.2386C84.3454 11.1557 85.3912 11.1126 86.4488 11.1093L86.4387 10.791ZM85.8324 12.8948C85.2161 12.8948 84.5795 12.8947 83.9177 12.9743C83.3107 13.0164 82.7097 13.1197 82.1242 13.2827C81.6331 13.4181 81.178 13.6575 80.7905 13.9839C80.62 14.144 80.4866 14.3383 80.3994 14.5537C80.3122 14.7692 80.2733 15.0006 80.2853 15.2322C80.2746 15.5498 80.3514 15.8643 80.5076 16.1423C80.6565 16.3842 80.8606 16.5885 81.1037 16.7392C81.3531 16.8973 81.6309 17.0071 81.9221 17.0624C82.2259 17.1268 82.5359 17.1585 82.8467 17.1569C83.3422 17.1872 83.8384 17.1108 84.3008 16.933C84.7633 16.7552 85.1808 16.4803 85.5242 16.1274C86.1581 15.4155 86.4923 14.4924 86.4589 13.5463V12.8948H85.8324Z"
        fill="#3E4145"
      />
    </svg>
  )
}

export default AppLogo
