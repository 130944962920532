import React from 'react'
import Head from 'next/head'
import { IS_DEVELOPMENT, IS_STAGEING } from 'settings'

type WithHeadProps = {
  /**
   * ページタイトル
   */
  title: string
  /**
   * head内に設定したいlink,script,metaなど
   */
  children?: React.ReactNode
}

const siteName = `${
  IS_DEVELOPMENT ? 'LOCAL ' : IS_STAGEING ? 'STAGE ' : ''
} Logiura`

/**
 * next/headのラッパー。page固有のheadの内容を設定する
 */
const WithHead = ({ title, children }: WithHeadProps): JSX.Element => {
  return (
    <Head>
      <title>
        {siteName} | {title}
      </title>
      {children}
    </Head>
  )
}

export default WithHead
