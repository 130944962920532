import { NextPage } from 'next'
import { Signin } from 'components/templates'
import { WithCsrf, WithHead } from 'components/compositions'

const PageSignin: NextPage = () => (
  <WithCsrf>
    <WithHead title="ログイン" />
    <Signin />
  </WithCsrf>
)

export default PageSignin
