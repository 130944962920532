import React from 'react'
import styles from './Card.module.css'

type CardProps = {
  children: React.ReactNode
  className?: string
}

/**
 * 白背景、角丸の枠 section
 */
const Card = ({ children, className = '' }: CardProps): JSX.Element => {
  return (
    <section
      className={`border bg-white p-5 md:rounded-md md:py-5 md:px-6 ${styles.card} ${className}`}
    >
      {children}
    </section>
  )
}

export default Card
