import type { UseQRcodeScannerReturn } from './QRcodeScanner.hooks'
import styles from './QRcodeScanner.module.css'

/**
 * QRコード読み取り部分
 */
const QRcodeScanner = ({
  svgRef,
  videoRef,
}: UseQRcodeScannerReturn['qrcodeScannerProps']): JSX.Element => (
  <div className="relative h-72 w-full overflow-hidden bg-gray">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="absolute top-1/2 left-1/2 h-40 w-40 -translate-x-1/2 -translate-y-1/2 animate-pulse fill-current text-white"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M15 8v8H5V8h10m1-2H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4V7c0-.55-.45-1-1-1z" />
    </svg>
    <video
      ref={videoRef}
      className="absolute top-1/2 left-1/2 max-w-none !-translate-x-1/2 !-translate-y-1/2"
    />
    <svg
      ref={svgRef}
      className="absolute top-1/2 left-1/2 !-translate-x-1/2 !-translate-y-1/2 overflow-hidden fill-current text-white transition-colors"
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197 200H155V197H197V155.5H200V197V200H197ZM200 45.5V3V0H197H155V3H197V45.5H200ZM45 200V197H3V155.5H0V197V200H3H45ZM0 45.5H3V3H45V0H3H0V3V45.5Z"
      />
      <rect
        x="0"
        y="45"
        width="3"
        height="111"
        fill="current"
        className={styles.left}
      />
      <rect
        x="197"
        y="45"
        width="3"
        height="111"
        fill="current"
        className={styles.right}
      />
      <rect
        x="45"
        y="0"
        width="111"
        height="3"
        fill="current"
        className={styles.top}
      />
      <rect
        x="45"
        y="197"
        width="111"
        height="3"
        fill="current"
        className={styles.bottom}
      />
    </svg>
  </div>
)

export default QRcodeScanner
