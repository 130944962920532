import React, { useMemo } from 'react'
import {
  useController,
  Control,
  FieldValues,
  Path,
  UseControllerProps,
} from 'react-hook-form'

type TextFieldProps<T extends FieldValues> = {
  /**
   * useFromの返り値
   */
  control: Control<T>
  /**
   * コンポーネントがアンマウントした時値を消すかどうか
   */
  shouldUnregister?: boolean
  /**
   * hook-formのバリデーションルール
   */
  rules?: UseControllerProps['rules']
  /**
   * マウント時のdefaultの値
   */
  defaultValue?: UseControllerProps['defaultValue']
  /**
   * name属性
   */
  name: Path<T>
  /**
   * 項目名
   */
  label?: string
  /**
   * type属性
   */
  type?: 'text' | 'password' | 'email'
  /**
   * 単位
   */
  adornment?: React.ReactNode
  /**
   * disabled属性
   */
  disabled?: boolean
  /**
   * プレースホルダー
   */
  placeholder?: string
  /**
   * 複数行入力にする(textareaになる)
   */
  multiline?: boolean
  className?: string
}

/**
 * input[type="text"]要素
 */
const TextField = <T extends FieldValues>({
  label,
  name,
  className = '',
  control,
  shouldUnregister,
  defaultValue,
  rules,
  adornment,
  disabled,
  type = 'text',
  placeholder,
  multiline = false,
}: TextFieldProps<T>): JSX.Element => {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, shouldUnregister, rules, defaultValue })

  const adornmentMemo = useMemo(
    () =>
      adornment && (
        <span className="ml-1 inline-block flex-none text-sm text-base">
          {adornment}
        </span>
      ),
    [adornment]
  )

  const labelMemo = useMemo(
    () =>
      label && (
        <label
          htmlFor={name}
          className={`mb-1.5 inline-block cursor-text font-bold text-current`}
        >
          {label}
        </label>
      ),
    [name, label]
  )

  const formAttributes = useMemo(
    () => ({
      id: name,
      className:
        'w-full text-sm flex-1 outline-none bg-transparent transition-colors placeholder-from disabled:text-form-disabled',
      disabled,
      'aria-errormessage': `${name}-error-messeage`,
    }),
    [name, disabled]
  )

  return (
    <div className={`max-w-sm ${className}`}>
      {labelMemo}
      <div
        className={[
          'relative flex items-center rounded-sm px-2.5 transition-colors',
          'after:absolute after:bottom-0 after:left-0 after:w-full after:rounded-b-sm after:border-transparent after:transition-colors',
          error
            ? '| bg-error after:border-b after:border-error focus-within:after:border-b-2'
            : '| bg-form after:border-b-2 focus-within:!bg-form focus-within:after:border-primary hover:bg-form-hover',
          disabled ? '!bg-form-disabled' : '',
          multiline ? 'h-max py-2' : 'h-9',
        ].join(' ')}
      >
        {multiline ? (
          <textarea
            {...field}
            {...formAttributes}
            aria-invalid={!!error}
            rows={5}
          />
        ) : (
          <>
            <input
              {...field}
              {...formAttributes}
              aria-invalid={!!error}
              type={type}
              role="textbox"
              placeholder={placeholder}
            />
            {adornmentMemo}
          </>
        )}
      </div>
      {error && (
        <div className="mt-2.5 flex items-center space-x-1 text-error">
          <svg
            width="18"
            height="15"
            viewBox="0 0 18 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-current"
          >
            <path d="M9 3.4925L14.6475 13.25H3.3525L9 3.4925ZM9 0.5L0.75 14.75H17.25L9 0.5ZM9.75 11H8.25V12.5H9.75V11ZM9.75 6.5H8.25V9.5H9.75V6.5Z" />
          </svg>
          <span id={formAttributes['aria-errormessage']}>{error.message}</span>
        </div>
      )}
    </div>
  )
}

export default TextField
