import React from 'react'
import { useRouter } from 'next/router'
import { useErrorHandler } from 'react-error-boundary'
import { useEffectOnce } from 'react-use'
import { php } from 'utilitys/query'

type WithCSRFProps = {
  children: React.ReactNode
}

/**
 * 非ログイン画面をラップする。/auth/csrfを叩いてログイン済みなら/homeにリダイレクトする。
 */
const WithCsrf = ({ children }: WithCSRFProps): JSX.Element => {
  const { replace } = useRouter()
  const handleError = useErrorHandler()
  useEffectOnce(() => {
    const csrf = async () => {
      try {
        const { headers } = await php.auth.csrf_cookie.get()
        if (headers['content-location'] === '/auth/me') {
          replace('/home')
        }
      } catch (err) {
        handleError(err)
      }
    }
    csrf()
  })

  return <>{children}</>
}

export default WithCsrf
