import { RegisterOptions } from 'react-hook-form'
import { Option } from 'components/atoms'

type Rule<T extends keyof RegisterOptions> = Pick<Required<RegisterOptions>, T>

/**
 * 必須項目です
 */
export const required: Rule<'required'> = {
  required: {
    value: true,
    message: '必須項目です',
  },
}

/**
 * 半角英数とドット(.)でご指定ください
 */
export const alphaNumDotPattern: Rule<'pattern'> = {
  pattern: {
    value: /^[a-z0-9.]*$/,
    message: '半角英数(小文字のみ)とドット(.)でご指定ください',
  },
}

/**
 * 半角英数とドット(.)でご指定ください
 */
export const numberPattern: Rule<'pattern'> = {
  pattern: {
    value: /^[0-9]*$/,
    message: '半角数字でご指定ください',
  },
}

/**
 * 有効なメールアドレスを指定してください
 */
export const emailPattern: Rule<'pattern'> = {
  pattern: {
    value:
      /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
    message: '有効なメールアドレスを指定してください',
  },
}

/**
 * ${length}文字以下で指定してください
 */
export const maxLength = (length = 255): Rule<'maxLength'> => ({
  maxLength: {
    value: length,
    message: `${length}文字以下で指定してください`,
  },
})

/**
 * ${length}文字以上で指定してください
 */
export const minLength = (length = 255): Rule<'minLength'> => ({
  minLength: {
    value: length,
    message: `${length}文字以上で指定してください`,
  },
})

/**
 * 半角英数字記号を組み合わせて入力してください
 */
export const passwordPattern: Rule<'pattern'> = {
  pattern: {
    value: /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[!-~]+$/i,
    message: '半角英数字記号を組み合わせて入力してください',
  },
}

/**
 * 郵便番号を入力してください
 */
export const zipCodePattern: Rule<'pattern'> = {
  pattern: {
    value: /^\d{3}-\d{4}$/,
    message: '正しい郵便番号を入力してください。',
  },
}

/**
 * 電話番号を入力してください
 */
export const phonePattern: Rule<'pattern'> = {
  pattern: {
    value:
      /^0([0-9]-[0-9]{4}|[0-9]{2}-[0-9]{3}|[0-9]{3}-[0-9]{2}|[0-9]{4}-[0-9])-[0-9]{4}$/,
    message: '正しい電話番号を入力してください。',
  },
}

/**
 * { label: すべて, value: -1 }を引数の配列の先頭に追加して返す
 */
export const unshiftSubete = (options: Option<number>[]) => [
  { label: 'すべて', value: -1 },
  ...options,
]
