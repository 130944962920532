import React, { useEffect, useRef, useCallback } from 'react'
import QrScanner from 'qr-scanner'
import styles from './QRcodeScanner.module.css'

interface ScanRegion {
  x?: number
  y?: number
  width?: number
  height?: number
  downScaledWidth?: number
  downScaledHeight?: number
}
/**
 * カメラで映し出した中でスキャンを実行できるエリアの縦横サイズ
 */
const calculateScanRegion = (video: HTMLVideoElement): ScanRegion => {
  const SCAN_AREA_SIZE = 200 as const
  const x = video.videoWidth / 2 - SCAN_AREA_SIZE / 2
  const y = video.videoHeight / 2 - SCAN_AREA_SIZE / 2

  return {
    x,
    y,
    width: SCAN_AREA_SIZE,
    height: SCAN_AREA_SIZE,
  }
}

export type QRcodeScannerAnimateIndicator = (
  className: 'nothing' | 'detecting' | 'complete'
) => void

export type ScanHandler = (
  source: string,
  animateIndicator: QRcodeScannerAnimateIndicator
) => void | Promise<void>

export type UseQRcodeScannerReturn = {
  qrcodeScannerProps: {
    svgRef: React.RefObject<SVGSVGElement>
    videoRef: React.RefObject<HTMLVideoElement>
  }
  animateIndicator: QRcodeScannerAnimateIndicator
}

const THROTTLE_DURATION = 250

/**
 * QRcodeScannerを動かすために使うhooks
 */
export const useQRcodeScanner = (
  onScan: ScanHandler
): UseQRcodeScannerReturn => {
  const svgRef = useRef<SVGSVGElement>(null)
  const animateIndicator = useCallback<QRcodeScannerAnimateIndicator>(
    (className) => {
      switch (className) {
        case 'nothing': {
          svgRef.current?.classList.remove('text-info')
          svgRef.current?.classList.remove('text-success')
          svgRef.current?.classList.remove(styles.square)
          svgRef.current?.classList.add('text-white')

          return
        }
        case 'detecting': {
          svgRef.current?.classList.remove('text-success')
          svgRef.current?.classList.remove('text-white')
          svgRef.current?.classList.add('text-info')
          svgRef.current?.classList.add(styles.square)

          return
        }
        case 'complete': {
          svgRef.current?.classList.remove('text-info')
          svgRef.current?.classList.remove('text-white')
          svgRef.current?.classList.add('text-success')

          return
        }
        default: {
          const _className: never = className
          throw new Error(`className = ${_className} はありません`)
        }
      }
    },
    []
  )

  const videoRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    if (process.env.NODE_ENV === 'test') return
    if (!videoRef.current) {
      throw Error(
        '正しく画面が描画されませんでした。ブラウザをリロードしてください。'
      )
    }

    let throttle: null | number = null
    let prev: null | string = null

    const scanner = new QrScanner(
      videoRef.current,
      ({ data }) => {
        if (prev !== data) {
          throttle = Date.now() + THROTTLE_DURATION
          prev = data
          animateIndicator('detecting')

          return
        }

        if (!throttle || throttle > Date.now()) return

        onScan(data, animateIndicator)
        throttle = null
      },
      {
        onDecodeError: () => {
          throttle = null
          prev = null
          animateIndicator('nothing')
        },
        calculateScanRegion,
      }
    )

    scanner.start().catch(() => undefined)

    return () => {
      scanner.destroy()
    }
  }, [animateIndicator, onScan])

  return {
    qrcodeScannerProps: { svgRef, videoRef },
    animateIndicator,
  }
}
