import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { AppLogo, TextField, Button, Card } from 'components/atoms'
import {
  QRcodeScanner,
  useQRcodeScanner,
  QRcodeScannerAnimateIndicator,
} from 'components/organisms'
import {
  php,
  LogiAxiosError,
  useCommonErrorHandler,
  renderErrorToastMessage,
} from 'utilitys/query'
import {
  alphaNumDotPattern,
  /* passwordPattern,*/ required,
} from 'utilitys/form'

type SigninFormValue = Parameters<typeof php.auth.signin.$post>[number]['body']
const defaultValues: SigninFormValue = { username: '', password: '' }
const usernameRules = { ...required, ...alphaNumDotPattern }
const passwordRules = { ...required /* , ...passwordPattern */ }

/**
 * ログイン処理
 */
const phpCsrfAndAuthSigninWitnToast = (body: {
  username: string
  password: string
}): ReturnType<typeof php.auth.signin.$post> => {
  return toast.promise(php.auth.signin.$post({ body }), {
    pending: '認証中',
    success: 'お疲れさまです🐮',
    error: {
      render: renderErrorToastMessage((err) => {
        if (err.response?.status !== 401) return err.message

        return 'ユーザー名またはパスワードが異なります'
      }),
    },
  })
}

/**
 * ログインフォーム
 */
const Signin = (): JSX.Element => {
  const { control, handleSubmit, setError } = useForm<SigninFormValue>({
    defaultValues,
    mode: 'onBlur',
  })

  const { push, query } = useRouter()
  const { handleCommonError } = useCommonErrorHandler({ setError })
  const { mutate, isLoading, mutateAsync } = useMutation(
    phpCsrfAndAuthSigninWitnToast,
    {
      onSuccess: () => {
        push(typeof query.url === 'string' ? query.url : '/home')
      },
      onError: (error) => {
        if ((error as LogiAxiosError).response?.status !== 422) return
        handleCommonError(error)
      },
    }
  )

  const onScan = useCallback(
    async (source: string, animateIndicator: QRcodeScannerAnimateIndicator) => {
      try {
        const json = JSON.parse(source)
        const username = json?.username
        const password = json?.password
        if (typeof username === 'string' && typeof password === 'string') {
          animateIndicator('complete')
          await mutateAsync({ username, password })
        }
      } catch (_) {
        animateIndicator('nothing')
      } finally {
        animateIndicator('nothing')
      }
    },
    [mutateAsync]
  )
  const { qrcodeScannerProps } = useQRcodeScanner(onScan)

  return (
    <>
      <QRcodeScanner {...qrcodeScannerProps} />
      <main className="container mx-auto max-w-md pt-20">
        <h1 className="mb-11 text-center" aria-label="Logiura">
          <AppLogo width={131} />
        </h1>
        <form onSubmit={handleSubmit((body) => mutate(body))}>
          <Card className="flex flex-col space-y-5 p-8">
            <TextField
              control={control}
              rules={usernameRules}
              name="username"
              label="アカウント名"
              className="max-w-none"
            />
            <TextField
              control={control}
              rules={passwordRules}
              name="password"
              label="パスワード"
              type="password"
              className="max-w-none"
            />
            <Button
              type="submit"
              variant="contained"
              className="self-end"
              disabled={isLoading}
            >
              ログイン
            </Button>
          </Card>
        </form>
      </main>
    </>
  )
}

export default Signin
